import React from "react";
import "./ClubInfoStatus.css";
import { useTranslation } from "react-i18next";

const ClubInfo = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="club-info-status-container">
      <div className="club-info-status-header">
        <h2>{t("TheClubStatus.TheClubStatus")}</h2>
      </div>
      <div className="club-info-status-body">
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus1")}
            <br />
            <br />
            {t("TheClubStatus.TheClubStatus2")}
            <br />
            {t("TheClubStatus.TheClubStatus3")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus4")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus5")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus6")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus7")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus8")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus9")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus10")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus11")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus12")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus13")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus14")}
            <br />
            {t("TheClubStatus.TheClubStatus15")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus16")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus17")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus18")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus19")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus20")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus21")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus22")}
            <br />
            {t("TheClubStatus.TheClubStatus23")}
            <br />
            {t("TheClubStatus.TheClubStatus24")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus25")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus26")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus27")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus28")}
          <br />
          {t("TheClubStatus.TheClubStatus29")}
          <br />
          {t("TheClubStatus.TheClubStatus30")}
          <br />
          {t("TheClubStatus.TheClubStatus31")}
          <br />
          {t("TheClubStatus.TheClubStatus32")}
          <br />
          {t("TheClubStatus.TheClubStatus33")}
          <br />
          {t("TheClubStatus.TheClubStatus34")}
          <br />
          {t("TheClubStatus.TheClubStatus35")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus36")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus37")}
          <br />
          {t("TheClubStatus.TheClubStatus38")}
          <br />
          {t("TheClubStatus.TheClubStatus39")}
          <br />
          {t("TheClubStatus.TheClubStatus40")}
          <br />
          {t("TheClubStatus.TheClubStatus41")}
          <br />
          {t("TheClubStatus.TheClubStatus42")}
          <br />
          {t("TheClubStatus.TheClubStatus43")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus44")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus45")}
          <br />
          {t("TheClubStatus.TheClubStatus46")}
          <br />
          {t("TheClubStatus.TheClubStatus47")}
          <br />
          {t("TheClubStatus.TheClubStatus48")}
          <br />
          {t("TheClubStatus.TheClubStatus49")}
          <br />
          {t("TheClubStatus.TheClubStatus50")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus51")}
            <br />
            {t("TheClubStatus.TheClubStatus52")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus53")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus54")}
          <br />
          {t("TheClubStatus.TheClubStatus55")}
          <br />
          {t("TheClubStatus.TheClubStatus56")}
          <br />
          {t("TheClubStatus.TheClubStatus57")}
          <br />
          {t("TheClubStatus.TheClubStatus58")}
          <br />
          {t("TheClubStatus.TheClubStatus59")}
          <br />
          {t("TheClubStatus.TheClubStatus60")}
          <br />
          {t("TheClubStatus.TheClubStatus61")}
          <br />
          {t("TheClubStatus.TheClubStatus62")}
          <br />
          {t("TheClubStatus.TheClubStatus63")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus64")}</strong>

          <br />
          {t("TheClubStatus.TheClubStatus65")}
          <br />
          {t("TheClubStatus.TheClubStatus66")}
          <br />
          {t("TheClubStatus.TheClubStatus67")}
          <br />
          {t("TheClubStatus.TheClubStatus68")}
          <br />
          {t("TheClubStatus.TheClubStatus69")}
          <br />
          {t("TheClubStatus.TheClubStatus70")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus71")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus72.1")}
          <br />
          {t("TheClubStatus.TheClubStatus72.2")}
          <br />
          {t("TheClubStatus.TheClubStatus72.3")}
          <br />
          {t("TheClubStatus.TheClubStatus72.4")}
          <br />
          {t("TheClubStatus.TheClubStatus72.5")}
          <br />
          {t("TheClubStatus.TheClubStatus72.6")}
          <br />
          {t("TheClubStatus.TheClubStatus72.7")}
          <br />
          {t("TheClubStatus.TheClubStatus72.8")}
          <br />
          {t("TheClubStatus.TheClubStatus72.9")}
          <br />
          {t("TheClubStatus.TheClubStatus72.10")}
          <br />
          {t("TheClubStatus.TheClubStatus72.11")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus74")}
            <br />
            {t("TheClubStatus.TheClubStatus75")}
          </strong>
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus76")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus77")}
          <br />
          {t("TheClubStatus.TheClubStatus78")}
          <br />
          {t("TheClubStatus.TheClubStatus79")}
          <br />
          {t("TheClubStatus.TheClubStatus79.1")}
          <br />
          {t("TheClubStatus.TheClubStatus79.2")}
          <br />
          {t("TheClubStatus.TheClubStatus79.3")}
          <br />
          {t("TheClubStatus.TheClubStatus79.4")}
          <br />
          {t("TheClubStatus.TheClubStatus79.5")}
          <br />
          {t("TheClubStatus.TheClubStatus79.6")}
          <br />
          {t("TheClubStatus.TheClubStatus79.7")}
          <br />
          {t("TheClubStatus.TheClubStatus80")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus81")}
            <br />
            {t("TheClubStatus.TheClubStatus82")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus83")}</strong> <br />
          {t("TheClubStatus.TheClubStatus84")}
          <br />
          {t("TheClubStatus.TheClubStatus85")}
          <br />
          {t("TheClubStatus.TheClubStatus86")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus87")}</strong> <br />
        
          {t("TheClubStatus.TheClubStatus88")}
          <br />
          {t("TheClubStatus.TheClubStatus89")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus90")}</strong> <br />
          {t("TheClubStatus.TheClubStatus91")}
          <br />
         
          <br />
          <br />
          <strong> {t("TheClubStatus.TheClubStatus92")}</strong> <br />
          {t("TheClubStatus.TheClubStatus93")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus94")}</strong> <br />
          {t("TheClubStatus.TheClubStatus95")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus96")}</strong> <br />
          {t("TheClubStatus.TheClubStatus97")}
          <br />
          {t("TheClubStatus.TheClubStatus98")} <br />
        </p>
        <br />
        <p>
          <strong>
          {t("TheClubStatus.TheClubStatus99.0")}
          <br />
            {t("TheClubStatus.TheClubStatus99")}
            <br />
            
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus100")}</strong> <br />
          {t("TheClubStatus.TheClubStatus101")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus102")}</strong> <br />
          {t("TheClubStatus.TheClubStatus103")}
          <br />
          {t("TheClubStatus.TheClubStatus104")}
          <br />
          {t("TheClubStatus.TheClubStatus105")}
          <br />
          {t("TheClubStatus.TheClubStatus106")}
          <br />
          {t("TheClubStatus.TheClubStatus107")}
          <br />
          {t("TheClubStatus.TheClubStatus108")}
          <br />
          {t("TheClubStatus.TheClubStatus109")}
          <br />
          {t("TheClubStatus.TheClubStatus110")}
          <br />
          {t("TheClubStatus.TheClubStatus111")}
          <br />
          {t("TheClubStatus.TheClubStatus112")}
          <br />
          {t("TheClubStatus.TheClubStatus113")}
          <br />
          {t("TheClubStatus.TheClubStatus114")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus115")}</strong> <br />
          {t("TheClubStatus.TheClubStatus116")}
          <br />
          {t("TheClubStatus.TheClubStatus117")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus118")}</strong> <br />
          {t("TheClubStatus.TheClubStatus119")}
          <br />
          {t("TheClubStatus.TheClubStatus120")}
          <br />
          {t("TheClubStatus.TheClubStatus121")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus122")}</strong> <br />
          {t("TheClubStatus.TheClubStatus123")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus124")}</strong> <br />
          {t("TheClubStatus.TheClubStatus125")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus126")}</strong> <br />
          {t("TheClubStatus.TheClubStatus127")}
          <br />
          {t("TheClubStatus.TheClubStatus128")}
          <br />
          {t("TheClubStatus.TheClubStatus129")}
          <br />
          {t("TheClubStatus.TheClubStatus130")}
          <br />
          {t("TheClubStatus.TheClubStatus131")}
          <br />
          {t("TheClubStatus.TheClubStatus132")}
          <br />
          {t("TheClubStatus.TheClubStatus133")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus134")}</strong> <br />
          {t("TheClubStatus.TheClubStatus135")}
          <br />
          
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus136")}</strong> <br />
          {t("TheClubStatus.TheClubStatus137")}
          <br />
          {t("TheClubStatus.TheClubStatus138")}
          <br />
          {t("TheClubStatus.TheClubStatus139")}
          <br />
          {t("TheClubStatus.TheClubStatus140")}
          <br />
          {t("TheClubStatus.TheClubStatus141")}
          <br />
          {t("TheClubStatus.TheClubStatus142")}
          <br />
          {t("TheClubStatus.TheClubStatus143")}
          <br />
          {t("TheClubStatus.TheClubStatus144")}
          <br />
          {t("TheClubStatus.TheClubStatus145")}
          <br />
          {t("TheClubStatus.TheClubStatus146")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus146")}</strong> <br />
          {t("TheClubStatus.TheClubStatus147")}
          <br />
          {t("TheClubStatus.TheClubStatus148")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus148")} </strong> <br />
          {t("TheClubStatus.TheClubStatus149")}
          <br />
          {t("TheClubStatus.TheClubStatus150")}
          <br />
          {t("TheClubStatus.TheClubStatus151")}
          <br />
          {t("TheClubStatus.TheClubStatus152")}
          <br />
          {t("TheClubStatus.TheClubStatus153")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus154")}
            <br />
            
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus155")}</strong> <br />
          {t("TheClubStatus.TheClubStatus156")}
          <br />
          {t("TheClubStatus.TheClubStatus157")}
          <br />
          {t("TheClubStatus.TheClubStatus158")}
          <br />
          {t("TheClubStatus.TheClubStatus159")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus159")}</strong> <br />
          {t("TheClubStatus.TheClubStatus160")}
          <br />
          {t("TheClubStatus.TheClubStatus161")}
          <br />
          {t("TheClubStatus.TheClubStatus162")}
          <br />
          {t("TheClubStatus.TheClubStatus163")}
          <br />
          {t("TheClubStatus.TheClubStatus164")}
          <br />
          {t("TheClubStatus.TheClubStatus165")}
          <br />
          {t("TheClubStatus.TheClubStatus166")}
          <br />
          {t("TheClubStatus.TheClubStatus167")}
          <br />
          {t("TheClubStatus.TheClubStatus168")}
          <br />
          {t("TheClubStatus.TheClubStatus169")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus169")}</strong> <br />
          {t("TheClubStatus.TheClubStatus170")}
          <br />
          {t("TheClubStatus.TheClubStatus171")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus172")}</strong> <br />
          
          <br />
          {t("TheClubStatus.TheClubStatus173.1")}
          <br />
          {t("TheClubStatus.TheClubStatus173.2")}
          <br />
          {t("TheClubStatus.TheClubStatus173.3")}
          <br />
          {t("TheClubStatus.TheClubStatus173.4")}
          <br />
          {t("TheClubStatus.TheClubStatus173.5")}
          <br />
          {t("TheClubStatus.TheClubStatus173.6")}
          <br />
          {t("TheClubStatus.TheClubStatus173.7")}
          <br />
          {t("TheClubStatus.TheClubStatus173.8")}
          <br />
          {t("TheClubStatus.TheClubStatus173.9")}
          <br />
          {t("TheClubStatus.TheClubStatus173.10")}
          <br />
          {t("TheClubStatus.TheClubStatus173.11")}
          <br />
          {t("TheClubStatus.TheClubStatus173.12")}
          <br />
          {t("TheClubStatus.TheClubStatus173.13")}
          <br />
          {t("TheClubStatus.TheClubStatus173.14")}
          <br />
          {t("TheClubStatus.TheClubStatus173.15")}
          <br />
          {t("TheClubStatus.TheClubStatus173.16")}
          <br />
          {t("TheClubStatus.TheClubStatus173.17")}
          <br />
          {t("TheClubStatus.TheClubStatus173.18")}
          <br />
          {t("TheClubStatus.TheClubStatus173.19")}
          <br />
          {t("TheClubStatus.TheClubStatus173.20")}
          <br />
          {t("TheClubStatus.TheClubStatus173.21")}
          <br />
          {t("TheClubStatus.TheClubStatus173.22")}
          <br />
          {t("TheClubStatus.TheClubStatus173.23")}
          <br />
          {t("TheClubStatus.TheClubStatus173.24")}
          <br />
          {t("TheClubStatus.TheClubStatus173.25")}
          <br />
          {t("TheClubStatus.TheClubStatus173.26")}
          <br />
          {t("TheClubStatus.TheClubStatus173.27")}
          <br />
          {t("TheClubStatus.TheClubStatus173.28")}
          <br />
          {t("TheClubStatus.TheClubStatus173.29")}
          <br />
          {t("TheClubStatus.TheClubStatus173.30")}
          <br />
          {t("TheClubStatus.TheClubStatus173.31")}
          <br />
          
          

          <br />
          <strong>{t("TheClubStatus.TheClubStatus197")}</strong> <br />
          
     
          {t("TheClubStatus.TheClubStatus197.1")}
          <br />
          {t("TheClubStatus.TheClubStatus197.2")}
          <br />
          {t("TheClubStatus.TheClubStatus197.3")}
          <br />
          {t("TheClubStatus.TheClubStatus197.4")}
          <br />
          {t("TheClubStatus.TheClubStatus197.5")}
          <br />
          
          <br />
          <strong>{t("TheClubStatus.TheClubStatus200")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus200.1")}
          <br />
          {t("TheClubStatus.TheClubStatus200.2")}
          <br />
          {t("TheClubStatus.TheClubStatus200.3")}
          <br />
          {t("TheClubStatus.TheClubStatus200.4")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus202")}</strong> <br />
          {t("TheClubStatus.TheClubStatus203")}
          <br />
          {t("TheClubStatus.TheClubStatus204")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus205")}</strong> <br />
          {t("TheClubStatus.TheClubStatus205.1")}
          <br />
          {t("TheClubStatus.TheClubStatus205.2")}
          <br />
          {t("TheClubStatus.TheClubStatus205.3")}
          <br />
          {t("TheClubStatus.TheClubStatus205.4")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus208")}
            <br />
            {t("TheClubStatus.TheClubStatus209")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus210")}</strong> <br />
          {t("TheClubStatus.TheClubStatus211")}
          <br />
          {t("TheClubStatus.TheClubStatus212")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus213")}</strong> <br />
          {t("TheClubStatus.TheClubStatus214")}
          <br />
          {t("TheClubStatus.TheClubStatus215")}
          <br />
          {t("TheClubStatus.TheClubStatus216")}
          <br />
          {t("TheClubStatus.TheClubStatus217")}
          <br />
          {t("TheClubStatus.TheClubStatus218")}
          <br />
          {t("TheClubStatus.TheClubStatus219")}
          <br />
          {t("TheClubStatus.TheClubStatus220")}
          <br />
          {t("TheClubStatus.TheClubStatus220.1")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus221")}
            <br />
            {t("TheClubStatus.TheClubStatus222")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus223")}</strong> <br />
          {t("TheClubStatus.TheClubStatus224")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus225")}</strong> <br />
          {t("TheClubStatus.TheClubStatus226")}
          <br />
          {t("TheClubStatus.TheClubStatus227")}
          <br />
          {t("TheClubStatus.TheClubStatus228")}
          <br />
          {t("TheClubStatus.TheClubStatus229")}
          <br />
          {t("TheClubStatus.TheClubStatus230")}
          <br />
          {t("TheClubStatus.TheClubStatus231")}
          <br />
          {t("TheClubStatus.TheClubStatus232")}
          <br />
          {t("TheClubStatus.TheClubStatus233")}
          <br />
          {t("TheClubStatus.TheClubStatus234")}
          <br />
          {t("TheClubStatus.TheClubStatus235")}
          <br />
          {t("TheClubStatus.TheClubStatus236")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus237")}</strong> <br />
          {t("TheClubStatus.TheClubStatus238")}
          <br />
          {t("TheClubStatus.TheClubStatus239")}
          <br />
          {t("TheClubStatus.TheClubStatus239.1")}
          <br />
          
          <br />
          <strong>{t("TheClubStatus.TheClubStatus241")}</strong> <br />
          {t("TheClubStatus.TheClubStatus242")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus243")}</strong> <br />
          {t("TheClubStatus.TheClubStatus244")}
          <br />
          {t("TheClubStatus.TheClubStatus245")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus246")}</strong> <br />
          {t("TheClubStatus.TheClubStatus247")}
          <br />
          {t("TheClubStatus.TheClubStatus248")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus249")}
            <br />
            {t("TheClubStatus.TheClubStatus250")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus255")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus256")}
          <br />
          {t("TheClubStatus.TheClubStatus257")}
          <br />
          {t("TheClubStatus.TheClubStatus258")}
          <br />
          {t("TheClubStatus.TheClubStatus259")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus260")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus261")}
          <br />
          {t("TheClubStatus.TheClubStatus262")}
          <br />
          {t("TheClubStatus.TheClubStatus263")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus264")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus265")}
          <br />
          {t("TheClubStatus.TheClubStatus266")}
          <br />
          {t("TheClubStatus.TheClubStatus267")}
          <br />
          {t("TheClubStatus.TheClubStatus267.1")}
          <br />
     
          <br />
          <strong>{t("TheClubStatus.TheClubStatus269")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus270")}
          <br />
          {t("TheClubStatus.TheClubStatus271")}
          <br />
          {t("TheClubStatus.TheClubStatus272")}
          <br />
          {t("TheClubStatus.TheClubStatus273")}
          <br />
        </p>
        <br />
        <br />
        
        <br />
        
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus274")}
            <br />
            {t("TheClubStatus.TheClubStatus275")}
          </strong>
        </p>
        <br />
        <p>
          <br />
          <strong>{t("TheClubStatus.TheClubStatus276")}</strong> <br />
          {t("TheClubStatus.TheClubStatus277")}
          <br />
          {t("TheClubStatus.TheClubStatus278")}
          <br />
          {t("TheClubStatus.TheClubStatus279")}
          <br />
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus282")}
            <br />
            {t("TheClubStatus.TheClubStatus283")}
          </strong>
        </p>
        <p>
          <br />
          <strong>{t("TheClubStatus.TheClubStatus284")}</strong> <br />
          {t("TheClubStatus.TheClubStatus285")}
          <br />
          {t("TheClubStatus.TheClubStatus286")}
          <br />
          {t("TheClubStatus.TheClubStatus287")}
          <br />
          {t("TheClubStatus.TheClubStatus288")}
          <br />
          {t("TheClubStatus.TheClubStatus289")}
          <br />
          {t("TheClubStatus.TheClubStatus290")}
          <br />
          {t("TheClubStatus.TheClubStatus291")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus292")}</strong> <br />
          {t("TheClubStatus.TheClubStatus293")}
          <br />
          {t("TheClubStatus.TheClubStatus294")}
          <br />
          {t("TheClubStatus.TheClubStatus295")}
          <br />
          {t("TheClubStatus.TheClubStatus296")}
          <br />
          {t("TheClubStatus.TheClubStatus297")}
          <br />
          {t("TheClubStatus.TheClubStatus298")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus299")}</strong> <br />
          {t("TheClubStatus.TheClubStatus300")}
          <br />
          {t("TheClubStatus.TheClubStatus301")}
          <br />
          {t("TheClubStatus.TheClubStatus302")}
          <br />
          {t("TheClubStatus.TheClubStatus303")}
          <br />
          {t("TheClubStatus.TheClubStatus304")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus305")}
            <br />
            {t("TheClubStatus.TheClubStatus306")}
          </strong>
        </p>
        <br />
        <p>
          <br />
          <strong>{t("TheClubStatus.TheClubStatus307")}</strong> <br />
          {t("TheClubStatus.TheClubStatus308")}
          <br />
          {t("TheClubStatus.TheClubStatus309")}
          <br />
          {t("TheClubStatus.TheClubStatus310")}
          <br />
          {t("TheClubStatus.TheClubStatus311")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus312")}</strong> <br />
          {t("TheClubStatus.TheClubStatus313")}
          <br />
        </p>
      </div>
      <br /> <br /> <br /> <br />
    </div>
  );
};

export default ClubInfo;
