import React, { useState } from "react";
import "./MembershipInfo.css";
import { useTranslation } from "react-i18next";
import MembershipForm from "./MembershipForm";

// Import the PDF files
import pdfOne from "../../../PDFs/CGerais-Automovel-1.pdf";
import pdfTwo from "../../../PDFs/Caravela Assistência Viagem_Veículos Clássicos.pdf";

const MembershipInfo = () => {
  const { t } = useTranslation();
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  return (
    <div className="membership-infoClassic">
      <br></br>
      <h2 className="membership-titleClassic">
        {t("InsuranceClassic.InsuranceClassic")}
      </h2>
      <p>{t("InsuranceClassic.InsuranceClassic2")}</p>
      <br></br>
      <h3 className="membership-subtitleClassic">
        {t("InsuranceClassic.InsuranceClassic5")}
      </h3>
      <br></br>
      <p>{t("InsuranceClassic.InsuranceClassic6")}</p>
      <br></br>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic7")}</strong>
      </p>
      <br></br>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic8")}</strong>{" "}
        {t("InsuranceClassic.InsuranceClassic9")}
      </p>
      <br></br>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic10")}</strong>
      </p>
      <br></br>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic11")}</strong>{" "}
        {t("InsuranceClassic.InsuranceClassic12")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic13")}</strong>{" "}
        {t("InsuranceClassic.InsuranceClassic14")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic15")}</strong>{" "}
        {t("InsuranceClassic.InsuranceClassic16")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic17")}</strong>{" "}
        {t("InsuranceClassic.InsuranceClassic18")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic19")}</strong>{" "}
        {t("InsuranceClassic.InsuranceClassic20")}
      </p>
      <br></br>
      <br></br>
      <p>{t("InsuranceClassic.InsuranceClassic23")}</p>
      <br></br>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic24")}</strong>{" "}
        {t("InsuranceClassic.InsuranceClassic25")}
      </p>
      <p>{t("InsuranceClassic.InsuranceClassic26")}</p>
      <p>{t("InsuranceClassic.InsuranceClassic27")}</p>
      <br></br>
      <h3 className="membership-subtitleClassic">
        {t("InsuranceClassic.InsuranceClassic28")}
      </h3>
      <br></br>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic29")}</strong>
        {t("InsuranceClassic.InsuranceClassic30")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic31")}</strong>
        {t("InsuranceClassic.InsuranceClassic32")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic33")}</strong>
        {t("InsuranceClassic.InsuranceClassic34")}
      </p>
      <p>
        <strong>{t("InsuranceClassic.InsuranceClassic35")}</strong>
        {t("InsuranceClassic.InsuranceClassic36")}
      </p>
      <p>
        {t("InsuranceClassic.InsuranceClassic37")}{" "}
        <a
          href={pdfOne}
          target="_blank"
          rel="noopener noreferrer"
          className="clickable-link"
        >
          {t("InsuranceClassic.InsuranceClassic38")}
        </a>{" "}
        {t("InsuranceClassic.InsuranceClassic39")}{" "}
        <a
          href={pdfTwo}
          target="_blank"
          rel="noopener noreferrer"
          className="clickable-link"
        >
          {t("InsuranceClassic.InsuranceClassic40")}
        </a>
      </p>
      <button
        className="join-button"
        onClick={() => setShowMembershipForm(true)}
      >
        {t("TheClubRegistationForm.TheClubRegistationForm67")}
      </button>
      {showMembershipForm && (
        <MembershipForm onClose={() => setShowMembershipForm(false)} />
      )}
    </div>
  );
};

export default MembershipInfo;
